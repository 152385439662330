@import '../../assets/sass/theme';

.logoNavbar {
  width: 180px;
  padding: 20px;
}

.logoutBox {
  margin-top: auto;
}

.navBox {
  height: 100vh;
}

#logoutButton {
  color: $red;
  border-color: $red;
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  margin: 20px auto;
}

// Removing default navbar border
.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  border: none;
}

.selectedNavbarItem {
  background-color: $white !important;
  color: $blaise-green !important;
  border-radius: 30px 0 0 30px;
}

.tabMenuItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabSubMenuItem {
  // to align sub item to the right of main item title
  // use width of icon and entity for space
  padding-left: 1.75em;
}

.navbar-items-container {
  overflow-y: auto;
  width: 220px;
  overflow-x: hidden;
}

.navbar-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
