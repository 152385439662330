@import '../../assets/sass/theme';

.paper {
  // Fullscreen(100vh) - outer page padding (60px) - hero image (30vh) - margin under hero image(24px) - title (53px) - extra spacing between card rows (12px) - 18px buffer (I don't know how my math doesn't work :( ), divided by 2 for the 2 rows of cards
  height: calc((100vh - 60px - 30vh - 24px - 53px - 12px - 18px) / 2);
  padding: 24px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: $blaise-light-green !important;

  .subtitle {
    text-align: center;
  }
}

@media screen and (max-width: 1500px) {
  .paper {
    height: calc((100vh - 60px - 20vh - 24px - 53px - 12px - 18px) / 2);
    padding: 12px !important;
  }
}

@media screen and (max-width: 1200px) {
  .subtitle {
    font-size: 14px;
  }
}

.title-container {
  margin-bottom: 12px;
}
