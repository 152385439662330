.bus-hero-image-container {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 24px;

  .bus-hero-image {
    width: 100%;
    height: 30vh;
    object-fit: cover;
    object-position: center calc(100% - 5%);
    transform: scale(1.1);
    filter: brightness(0.7);
  }
  @media screen and (max-width: 1500px) {
    .bus-hero-image {
      height: 20vh;
    }
  }
}

.larger-text {
  font-size: 18px !important;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .larger-text {
    font-size: 14px !important;
  }
}

.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .analytics-summary-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .centered-container {
    gap: 2px;
  }
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
