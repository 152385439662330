.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .blaise-loader {
    height: 60px;
    width: 60px;
  }
}
